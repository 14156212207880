import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RedirectService {
  public originalUrl: string;

  public initialNavigation = false;

  constructor(private router: Router) {}

  public setOriginalUrl(url: string) {
    this.originalUrl = url;
  }

  public redirectToMemorizedUrl(): void {
    if (this.originalUrl) {
      this.router.navigate([this.originalUrl]);

      this.originalUrl = null;
    }
  }
}
